import React, {useState}        from 'react';
import Typewriter   from 'typewriter-effect';
import IconsWrapper from './IconsWrapper';

import './styles/index.scss';

function Main() {
    const [nameClass, setNameClass] = useState('glitch-disabled');
    const [showIcons, setShowIcons] = useState(false);

    return (
        <>
            <div className="main-wrapper">
                <div className={nameClass} data-text="Tomasz Rembacz">
                    <Typewriter
                        onInit={typewriter => typewriter
                            .typeString('Tomasz Rembacz')
                            .pauseFor(500)
                            .callFunction(() => setNameClass('glitch'))
                            .start()
                        }
                        options={{cursor: '|', delay: 130}}
                    />
                </div>
                <div className="name">
                    <Typewriter
                        onInit={typewriter => typewriter
                            .pauseFor(2500)
                            .typeString('Software Engineer')
                            .pauseFor(500)
                            .callFunction(() => setShowIcons(true))
                            .start()}
                        options={{cursor: '', delay: 150}}
                    />
                </div>

                {showIcons ? <IconsWrapper /> : <div className="placeholder" />}
            </div>

            <div className="background">
                {/* <div className="sun"></div> */}
                <div className="grid"></div>
                <div className="overlay"></div>
            </div>
        </>
  );
}

export default Main;
