import React                              from 'react';
import { ReactComponent as GitHubIcon }   from './styles/github.svg';
import { ReactComponent as LinkedinIcon } from './styles/linkedin.svg';
// import { ReactComponent as ResumeIcon }   from './styles/resume.svg';

const IconsWrapper = () => (
    <div className="icons-wrapper">
        <div className="icon github">
            <a href="https://github.com/trembacz" target="_blank" rel="noreferrer" title="GitHub profile">
                <GitHubIcon />
            </a>
        </div>

        <div className="icon linkedin">
            <a href="https://www.linkedin.com/in/tomasz-rembacz/" target="_blank" rel="noreferrer" title="LinkedIn profile">
                <LinkedinIcon />
            </a>
        </div>

        {/* <div className="icon resume">
            <a href="https://mm85.org/_cv.pdf" target="_blank" rel="noreferrer" title="CV">
                <ResumeIcon />
            </a>
        </div> */}
    </div>
);

export default IconsWrapper;
